<template>
  <div class="spotify-layout">
    <!-- Sidebar -->
    <div class="sidebar" :class="{ 'collapsed': isCollapsed }">
      <div class="sidebar-content">
        <!-- Header del sidebar -->
        <div class="sidebar-header">
          <div class="library-title">
            <span class="library-icon">▤</span>
            <div class="title-text" v-show="!isCollapsed">Tu biblioteca</div>
          </div>
          <div class="header-actions">
            <button class="action-button" v-show="!isCollapsed">
              <span>+</span>
            </button>
            <button class="action-button" @click="toggleSidebar">
              <span v-if="isCollapsed">←</span>
              <span v-else>→</span>
            </button>
          </div>
        </div>

        <!-- Filtros -->
        <div class="filter-buttons" v-show="!isCollapsed">
          <button 
            class="filter-button" 
            :class="{ 'active': activeFilter === 'personal' }"
            @click="setFilter('personal')"
          >
            Por ti
          </button>
          <button 
            class="filter-button" 
            :class="{ 'active': activeFilter === 'brandme' }"
            @click="setFilter('brandme')"
          >
            Por BrandMe
          </button>
        </div>

        <!-- Barra de búsqueda -->
        <div class="search-bar" v-show="!isCollapsed">
          <div class="search-input-container">
            <span class="search-icon">○</span>
            <input 
              type="text" 
              class="search-input" 
              placeholder="Buscar en tu biblioteca"
              v-model="searchQuery"
            >
          </div>
          <button class="list-button">☰</button>
        </div>

        <!-- Lista de playlists -->
        <div class="playlists-container">
          <div 
            v-for="list in currentLists" 
            :key="list.id" 
            class="playlist-item"
            :class="{ 'active': selectedList && selectedList.id === list.id }"
            @click="selectList(list)"
          >
            <div class="playlist-image">
              <img v-if="list.cover" :src="list.cover" :alt="list.title">
              <div v-else class="playlist-image-placeholder">
                <span class="playlist-icon">♪</span>
              </div>
            </div>
            <div class="playlist-info">
              <div class="playlist-title">{{ list.title }}</div>
              <div class="playlist-subtitle">Lista • {{ list.owner }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Contenido -->
    <div class="contenido">
      <!-- Vista de detalle de lista -->
      <div v-if="selectedList" class="list-detail-view" @scroll="handleScroll">
        <!-- Header con gradiente -->
        <div class="list-detail-header" :style="getHeaderGradient">
          <button class="back-button detail-back" @click="closeListDetail">
            ←
          </button>
          <div class="list-header">
            <div class="list-cover">
              <img :src="selectedList.cover || 'path/to/default/image'" :alt="selectedList.title">
            </div>
            <div class="list-info">
              <div class="list-type">Playlist</div>
              <h1 class="list-title">{{ selectedList.title }}</h1>
              <div class="list-stats">
                <img class="owner-avatar" :src="selectedList.ownerAvatar" :alt="selectedList.owner">
                <span class="owner-name">{{ selectedList.owner }}</span>
                <span class="list-count">• {{ selectedListInfluencers.length }} influencers</span>
              </div>
            </div>
          </div>

        </div>

        <!-- Nav flotante -->
        <div class="floating-nav" :class="{ 'visible': showFloatingNav }">
          <div class="nav-content">
            <div class="nav-list-info">
              <img :src="selectedList.cover" :alt="selectedList.title" class="nav-cover">
              <span class="nav-title">{{ selectedList.title }}</span>
            </div>
            <button class="play-button nav-play">
              <span class="play-icon">▶</span>
            </button>
          </div>
        </div>

        <!-- Contenido scrolleable -->
        <div class="list-detail-content">
          <!-- Lista de influencers -->
          <div class="songs-list">
            <div class="songs-header">
              <div class="song-number">#</div>
              <div class="song-title">Influencer</div>
              <div class="song-album">Red social</div>
              <div class="song-duration">Seguidores</div>
              <div class="song-verified">Verificado</div>
            </div>
            
            <div class="songs-container">
              <div v-if="loading" class="loading-message">
                Cargando influencers...
              </div>
              <div v-else-if="selectedListInfluencers.length === 0" class="empty-message">
                No se encontraron influencers en esta lista
              </div>
              <template v-else>
                <div v-for="(influencer, index) in selectedListInfluencers" :key="influencer.uuid" class="song-item">
                  <div class="song-number">{{ index + 1 }}</div>
                  <div class="song-title">
                    <img :src="influencer.image" :alt="influencer.username" class="song-cover">
                    <div class="song-info">
                      <div class="song-name">{{ influencer.username }}</div>
                      <a :href="influencer.profile_url" target="_blank" class="profile-link">Ver perfil</a>
                    </div>
                  </div>
                  <div class="song-album">{{ influencer.network }}</div>
                  <div class="song-duration">{{ formatFollowers(influencer.followers) }}</div>
                  <div class="song-verified">
                    <span v-if="influencer.is_verified" class="verified-badge">✓</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- Vista principal (mostrar solo si no hay lista seleccionada) -->
      <template v-else>
        <!-- Sección "Hecho para" -->
        <section class="made-for-section" :class="{ 'full-view': showAllPersonalLists }">
          <div class="section-header">
            <div class="section-title">
              <button 
                v-if="showAllPersonalLists" 
                class="back-button" 
                @click="toggleShowPersonalLists"
              >
                ←
              </button>
              Hecho para Ricardo Manzanares Avila
            </div>
            <button 
              v-if="!showAllPersonalLists" 
              class="show-all" 
              @click="toggleShowPersonalLists"
            >
              Mostrar todo
            </button>
          </div>
          
          <!-- Vista normal -->
          <div v-if="!showAllPersonalLists" class="cards-grid">
            <div v-if="loading" class="loading-message">
              Cargando listas...
            </div>
            <div v-else-if="lists.length === 0" class="empty-message">
              No se encontraron listas personalizadas
            </div>
            <div 
              v-else
              v-for="list in lists.slice(0, 5)" 
              :key="list.uuid" 
              class="card"
              @click="selectList(list)"
            >
              <div class="card-image" :class="getRandomGradient()">
                <img 
                  :src="list.cover || 'path/to/default/image'" 
                  :alt="list.title"
                >
              </div>
              <div class="card-title">{{ list.title }}</div>
              <div class="card-description">{{ list.description || 'Lista personalizada' }}</div>
            </div>
          </div>

          <!-- Vista completa -->
          <div v-else class="cards-grid">
            <div v-if="loading" class="loading-message">
              Cargando listas...
            </div>
            <div v-else-if="lists.length === 0" class="empty-message">
              No se encontraron listas personalizadas
            </div>
            <div 
              v-else
              v-for="list in lists" 
              :key="list.uuid" 
              class="card"
              @click="selectList(list)"
            >
              <div class="card-image" :class="getRandomGradient()">
                <img 
                  :src="list.image || 'path/to/default/image'" 
                  :alt="list.title"
                >
              </div>
              <div class="card-title">{{ list.title }}</div>
              <div class="card-description">{{ list.description || 'Lista personalizada' }}</div>
            </div>
          </div>
        </section>

        <!-- Sección "Estaciones populares" -->
        <section class="popular-stations" :class="{ 'full-view': showAllPreLists }">
          <div class="section-header">
            <div class="section-title">
              <button 
                v-if="showAllPreLists" 
                class="back-button" 
                @click="toggleShowAllPreLists"
              >
                ←
              </button>
              Estaciones hechas por BrandMe
            </div>
            <button 
              v-if="!showAllPreLists" 
              class="show-all" 
              @click="toggleShowAllPreLists"
            >
              Mostrar todo
            </button>
          </div>
          
          <!-- Vista de scroll horizontal -->
          <div v-if="!showAllPreLists" class="cards-grid-scroll">
            <div class="cards-grid-horizontal">
              <div 
                v-for="list in preLists" 
                :key="list.id" 
                class="radio-card"
                @click="selectList(list)"
              >
                <div class="radio-image" :class="list.color || 'yellow'">
                  <span class="radio-label">RADIO</span>
                  <img 
                    :src="list.cover || 'path/to/default/image'" 
                    :alt="list.title"
                  >
                </div>
                <div class="card-title">{{ list.title }}</div>
                <div class="card-description">{{ list.description || 'Lista pre fabricada' }}</div>
              </div>
            </div>
          </div>

          <!-- Vista completa en grid -->
          <div v-else class="cards-grid">
            <div 
              v-for="list in preLists" 
              :key="list.id" 
              class="radio-card"
              @click="selectList(list)"
            >
              <div class="radio-image" :class="list.color || 'yellow'">
                <span class="radio-label">RADIO</span>
                <img 
                  :src="list.cover || 'path/to/default/image'" 
                  :alt="list.title"
                >
              </div>
              <div class="card-title">{{ list.title }}</div>
              <div class="card-description">{{ list.description || 'Lista pre fabricada' }}</div>
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import listService from '@/services/lists'

export default {
  name: 'ListV2',
  data() {
    return {
      isCollapsed: false,
      searchQuery: '',
      lists: [],
      preLists: [],
      selectedList: null,
      selectedListInfluencers: [],
      loading: true,
      activeFilter: 'personal',
      showAllPreLists: false,
      showAllPersonalLists: false,
      showFloatingNav: false
    }
  },
  computed: {
    filteredLists() {
      if (!this.searchQuery) return this.lists
      const query = this.searchQuery.toLowerCase()
      return this.lists.filter(list => list.title.toLowerCase().includes(query) || list.owner.toLowerCase().includes(query))
    },
    currentLists() {
      if (!this.searchQuery) {
        return this.activeFilter === 'personal' ? this.lists : this.preLists
      }
      const query = this.searchQuery.toLowerCase()
      const sourceList = this.activeFilter === 'personal' ? this.lists : this.preLists
      return sourceList.filter(list => list.title.toLowerCase().includes(query) || (list.owner && list.owner.toLowerCase().includes(query)))
    },
    getHeaderGradient() {
      if (this.selectedList?.color_palette) {
        const colors = this.selectedList.color_palette;
        if (Array.isArray(colors) && colors.length >= 2) {
          return {
            background: `linear-gradient(180deg, ${colors[0]} 0%, ${colors[1]} 85%, #121212 95%)`
          };
        }
        return {
          background: `linear-gradient(180deg, ${colors} 0%, rgba(18, 18, 18, 0.8) 85%, #121212 95%)`
        };
      }
      return {
        background: 'linear-gradient(180deg, rgba(83, 83, 83, 0.5) 0%, #121212 95%)'
      };
    }
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed
    },
    async loadInfluencers(uuid) {
      try {
        console.log('Cargando influencers para uuid:', uuid);
        const response = await listService.getInfluencers(uuid);
        console.log('Respuesta de influencers:', response);
        // Asegurarnos de que estamos asignando el array correcto
        this.selectedListInfluencers = Array.isArray(response) ? response : Array.isArray(response?.results) ? response.results : Array.isArray(response?.response) ? response.response : [];
        console.log('Influencers cargados:', this.selectedListInfluencers);
      } catch (error) {
        console.error('Error al cargar influencers:', error);
        this.selectedListInfluencers = [];
      }
    },
    async selectList(list) {
      console.log('Lista seleccionada:', list);
      this.selectedList = list;
      if (list && list.uuid) {
        await this.loadInfluencers(list.uuid);
      } else if (list && list.id) {
        // Si no hay uuid pero hay id, intentamos con el id
        await this.loadInfluencers(list.id);
      } else {
        console.error('La lista seleccionada no tiene uuid ni id:', list);
        this.selectedListInfluencers = [];
      }
    },
    fetchLists() {
      listService.getLists().then((response) => {
        console.log('Respuesta de listas:', response); // Para debug
        this.lists = Array.isArray(response) ? response : (response?.results || []);
        this.loading = false;
      }).catch(error => {
        console.error('Error al cargar listas:', error);
        this.lists = [];
        this.loading = false;
      })
    },
    fetchPreLists() {
      listService.getPreLists().then((response) => {
        console.log('Respuesta de pre-listas:', response); // Para debug
        this.preLists = Array.isArray(response) ? response : (response?.results || []);
        this.loading = false;
      }).catch(error => {
        console.error('Error al cargar pre-listas:', error);
        this.preLists = [];
        this.loading = false;
      })
    },
    toggleShowAllPreLists() {
      this.showAllPreLists = !this.showAllPreLists
    },
    getRandomGradient() {
      const gradients = [
        'gradient-purple',
        'gradient-blue',
        'gradient-cyan',
        'gradient-red',
        'gradient-pink'
      ];
      return gradients[Math.floor(Math.random() * gradients.length)];
    },
    setFilter(filter) {
      this.activeFilter = filter
      this.searchQuery = ''
    },
    toggleShowPersonalLists() {
      this.showAllPersonalLists = !this.showAllPersonalLists
    },
    formatFollowers(followers) {
      if (followers >= 1000000) {
        return `${(followers / 1000000).toFixed(1)}M`
      } else if (followers >= 1000) {
        return `${(followers / 1000).toFixed(1)}K`
      }
      return followers.toString()
    },
    closeListDetail() {
      this.selectedList = null;
      this.selectedListInfluencers = [];
    },
    handleScroll(event) {
      const headerHeight = 340; // Altura hasta el final del título grande
      const scrollTop = event.target.scrollTop;
      this.showFloatingNav = scrollTop >= headerHeight;
    }
  },
  created() {
    this.fetchLists()
    this.fetchPreLists()
  }
}
</script>

<style scoped>
.spotify-layout {
  display: flex;
  min-height: 100vh;
  background-color: white;
  padding: 8px;
  gap: 8px;
}

.sidebar {
  width: 300px;
  background-color: #121212;
  border-radius: 10px;
  padding: 16px;
  height: calc(100vh - 16px);
  transition: all 0.3s ease;
}

.sidebar.collapsed {
  width: 72px;
}

.sidebar-content {
  color: white;
}

.contenido {
  flex: 1;
  background-color: #121212;
  border-radius: 10px;
  padding: 24px;
  color: white;
  height: calc(100vh - 16px);
  overflow-y: auto;
  position: relative;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.section-header h2 {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.show-all {
  background: none;
  border: none;
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

.show-all:hover {
  color: white;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  margin-bottom: 48px;
}

.card, .radio-card {
  background: #181818;
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.card:hover, .radio-card:hover {
  background-color: #282828;
}

.card-image, .radio-image {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
}

.card-image img, .radio-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gradient-purple { background: linear-gradient(135deg, #450af5, #c4efd9); }
.gradient-blue { background: linear-gradient(135deg, #1e3264, #b4b4b4); }
.gradient-cyan { background: linear-gradient(135deg, #148a08, #b4b4b4); }
.gradient-red { background: linear-gradient(135deg, #e61e32, #b4b4b4); }
.gradient-pink { background: linear-gradient(135deg, #bc5900, #b4b4b4); }

.yellow { background-color: #ffd966; }
.green { background-color: #96d36f; }

.radio-label {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
}

h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 8px;
  color: white;
}

p {
  font-size: 14px;
  color: #b3b3b3;
  margin: 0;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Personalizar scrollbar */
.contenido::-webkit-scrollbar {
  width: 8px;
}

.contenido::-webkit-scrollbar-track {
  background: transparent;
}

.contenido::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.contenido::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  margin-bottom: 16px;
}

.library-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.library-icon {
  font-size: 24px;
  color: white;
}

.library-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin: 0;
}

.header-actions {
  display: flex;
  gap: 8px;
}

.action-button {
  background: none;
  border: none;
  color: #969696;
  font-size: 20px;
  padding: 8px;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.action-button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.filter-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  padding: 0 4px;
  flex-wrap: wrap;
}

.filter-button {
  background-color: #232323;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 32px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.filter-button:hover {
  background-color: #2a2a2a;
}

.filter-button.active {
  background-color: white;
  color: black;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.search-input-container {
  display: flex;
  align-items: center;
  background-color: #242424;
  border-radius: 4px;
  padding: 6px 12px;
  flex: 1;
  margin-right: 8px;
}

.search-icon {
  color: #969696;
  font-size: 16px;
  margin-right: 8px;
}

.search-input {
  background: transparent;
  border: none;
  color: white;
  font-size: 14px;
  width: 100%;
  padding: 0;
}

.search-input:focus {
  outline: none;
}

.search-input::placeholder {
  color: #969696;
}

.search-button:hover,
.list-button:hover {
  color: white;
}

.title-text {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.card-title {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin-bottom: 8px;
}

.card-description {
  font-size: 14px;
  color: #b3b3b3;
  margin: 0;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Eliminar los estilos anteriores de h1, h2, h3 */
.library-title .title-text {
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin: 0;
}

.playlists-container {
  margin-top: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.playlist-item {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.playlist-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.playlist-item.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.playlist-image {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.playlist-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playlist-image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #282828;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playlist-icon {
  font-size: 24px;
  color: #b3b3b3;
}

.playlist-info {
  flex: 1;
  min-width: 0;
}

.playlist-title {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playlist-subtitle {
  font-size: 12px;
  color: #b3b3b3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Personalizar scrollbar para playlists-container */
.playlists-container::-webkit-scrollbar {
  width: 8px;
}

.playlists-container::-webkit-scrollbar-track {
  background: transparent;
}

.playlists-container::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.playlists-container::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.cards-grid-scroll {
  overflow-x: auto;
  padding-bottom: 20px;
  margin-bottom: 28px;
  position: relative;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.cards-grid-scroll::-webkit-scrollbar {
  height: 6px;
}

.cards-grid-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.cards-grid-scroll::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.cards-grid-scroll::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.cards-grid-horizontal {
  display: flex;
  gap: 24px;
  padding: 4px;
}

.cards-grid-horizontal .radio-card {
  width: calc((100% - 96px) / 5);
  min-width: 180px;
  max-width: 200px;
  flex-shrink: 0;
}

/* Asegurarse de que las imágenes mantengan su aspecto */
.radio-card .radio-image {
  width: 100%;
  aspect-ratio: 1;
}

.popular-stations.full-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #121212;
  z-index: 10;
  padding: 24px;
  overflow-y: auto;
}

.sidebar.collapsed ~ .contenido .popular-stations.full-view {
  left: calc(72px + 16px);
}

.full-view .cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 24px;
  padding: 20px 0;
}

.full-view .section-header {
  position: sticky;
  top: 0;
  background-color: #121212;
  padding: 16px 0;
  z-index: 11;
  margin-bottom: 24px;
}

/* Ocultar otras secciones cuando está en vista completa */
.popular-stations.full-view ~ section {
  display: none;
}

.made-for-section {
  transition: opacity 0.3s ease;
}

.back-button {
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
  padding: 8px;
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
  transition: transform 0.2s;
  border-radius: 50%;
}

.back-button:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.1);
}

/* Estilos para la vista completa (común para ambas secciones) */
.full-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #121212;
  z-index: 10;
  padding: 24px;
  overflow-y: auto;
}

.full-view .section-header {
  position: sticky;
  top: 0;
  background-color: #121212;
  padding: 16px 0;
  z-index: 11;
  margin-bottom: 24px;
}

.full-view .cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 24px;
  padding: 20px 0;
}

/* Ocultar secciones cuando una está en vista completa */
.made-for-section.full-view ~ section,
.popular-stations.full-view ~ section {
  display: none;
}

/* Asegurar que las cards mantengan su aspecto en vista completa */
.full-view .card,
.full-view .radio-card {
  width: 100%;
  height: 100%;
}

.full-view .card-image,
.full-view .radio-image {
  width: 100%;
  aspect-ratio: 1;
}

/* Estilos para el botón de regresar */
.back-button {
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
  padding: 8px;
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
  transition: transform 0.2s;
  border-radius: 50%;
}

.back-button:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.1);
}

/* Transiciones suaves */
.made-for-section,
.popular-stations {
  transition: all 0.3s ease;
}

/* Estilos para la vista de detalle de lista */
.list-detail-view {
  height: 100%;
  position: relative;
  overflow-y: auto;
}

.list-detail-header {
  position: relative;
  z-index: 2;
}

.list-detail-content {
  position: relative;
  z-index: 1;
  background-color: #121212;
}

.list-header {
  position: relative;
  display: flex;
  padding: 64px 32px 0;
  gap: 24px;
  align-items: flex-end;
}

.list-cover {
  width: 232px;
  height: 232px;
  box-shadow: 0 4px 60px rgba(0, 0, 0, .5);
}

.list-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.list-info {
  flex: 1;
}

.list-type {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.list-title {
  font-size: 96px;
  font-weight: 900;
  margin: 8px 0;
  line-height: 96px;
}

.list-stats {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #b3b3b3;
  font-size: 14px;
}

.owner-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.owner-name {
  color: white;
  font-weight: 700;
}

.playback-controls {
  padding: 24px 32px;
}

.playback-info {
  display: flex;
  align-items: center;
  gap: 24px;
}

.play-button {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #1ed760;
  border: none;
  color: black;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  opacity: 0;
}

.playback-info:hover .play-button {
  opacity: 1;
}

.play-button:hover {
  transform: scale(1.05);
  background-color: #1fdf64;
}

.playback-title {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.playback-info:hover .playback-title {
  color: white;
}

.songs-list {
  padding: 0 32px;
}

.songs-header {
  display: grid;
  grid-template-columns: 48px 4fr 2fr 2fr 1fr;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #b3b3b3;
  font-size: 14px;
  font-weight: 500;
}

.songs-container {
  margin-top: 16px;
}

.song-item {
  display: grid;
  grid-template-columns: 48px 4fr 2fr 2fr 1fr;
  padding: 8px 16px;
  color: #b3b3b3;
  border-radius: 4px;
  font-size: 14px;
  align-items: center;
}

.song-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.song-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.song-cover {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.song-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.song-name {
  color: white;
  font-weight: 500;
}

.song-artist {
  color: #b3b3b3;
}

.song-number, .song-duration {
  text-align: center;
}

.song-duration {
  text-align: right;
  padding-right: 16px;
}

/* Ajustar el scroll para la vista de detalle */
.list-detail-view {
  height: 100%;
  overflow-y: auto;
}

.list-detail-view::-webkit-scrollbar {
  width: 8px;
}

.list-detail-view::-webkit-scrollbar-track {
  background: transparent;
}

.list-detail-view::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.list-detail-view::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.song-verified {
  text-align: center;
}

.verified-badge {
  color: #1ed760;
  font-size: 18px;
  font-weight: bold;
}

.profile-link {
  color: #b3b3b3;
  text-decoration: none;
  font-size: 12px;
}

.profile-link:hover {
  color: white;
  text-decoration: underline;
}

.loading-message,
.empty-message {
  grid-column: 1 / -1;
  text-align: center;
  padding: 32px;
  color: #b3b3b3;
  font-size: 16px;
  background: #181818;
  border-radius: 8px;
}

.loading-message {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.detail-back {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.detail-back:hover {
  background-color: rgba(0, 0, 0, 0.9);
  transform: scale(1.1);
}

.floating-nav {
  position: sticky;
  top: 0;
  height: 64px;
  background-color: rgba(18, 18, 18, 0.85);
  backdrop-filter: blur(12px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 3;
  padding: 0 32px;
  display: flex;
  align-items: center;
  margin: 0;
}

.floating-nav.visible {
  opacity: 1;
  visibility: visible;
}

.nav-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-list-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-cover {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  object-fit: cover;
}

.nav-title {
  font-size: 16px;
  font-weight: 700;
  color: white;
}

.nav-play {
  width: 32px;
  height: 32px;
  font-size: 16px;
  opacity: 1;
  background-color: #1ed760;
}
</style>

